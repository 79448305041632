
div
  input.form-control(v-model='form.name' placeholder='Firmenname' @keyup='debouncedSearch')

  a.btn.btn-sm.btn-link(:href='northdataLink' target='_blank' rel='noopener')
    |North-Data-Suche

  h5 Standorte aus Google Places

  i.fa.fa-cog.fa-spin.fa-3x.text-primary(v-if='isLoading')

  .panel.panel-default(v-for='result in googlePlacesResults')
    .panel-heading
      div
        |{{ result.name }}
      div: small
        |{{ result.ort }}
    .panel-body
      dl
        dt Website:
        dd
          a(:href='result.website' target='_blank')
            | {{ result.website }}
        dt Telefon:
        dd
          | {{ result.international_phone_number }}
        dt Adresse
        dd
          div
            | {{ result.strasse }}
            |
            | {{ result.hausnummer }}
          div
            | {{ result.plz }}
            |
            | {{ result.ort }}
    .panel-footer
      button.btn.btn-default(@click='copy(result)')
        |Übernehmen

  .form-group
    label
      |Straße
      input.form-control(v-model='form.strasse' placeholder='Straße')
  .form-group
    label
      |Hausnummer
      input.form-control(v-model='form.hausnummer' placeholder='Hausnummer')
  .form-group
    label
      |PLZ
      input.form-control(v-model='form.plz' placeholder='PLZ')
  .form-group
    label
      |Ort
      input.form-control(v-model='form.ort' placeholder='Ort')
  .form-group
    label
      |Website
      input.form-control(v-model='form.url' placeholder='Website')
  .form-group
    label
      |Telefon
      input.form-control(v-model='form.telefon' placeholder='Telefon')

  .form-group
    label Organisationstyp

    label(v-for='typ in organisationstypen')
      input(name='organisationstyp' v-model='form.organisationstyp' :value='typ.id' type='radio')
      |
      | {{ typ.name }}

  .form-group
    label Cluster

    div: label(v-for='typ in cluster')
      input(v-model='form.cluster' :value='typ.ID' type='checkbox')
      |
      | {{ typ.Kurzbezeichnung }}

  button.btn.btn-primary(@click.prevent='save' type='submit')
    |Anlegen


