
.panel.panel-primary
  .panel-heading
    h3.panel-title: a(@click.prevent='showAll = !showAll' href='#')
      span.badge.badge-dark
        | {{ vorgaenge.length }}
      |
      | Erinnerungen

  vorgaenge-table(:value="showVorgaenge" v-if='showVorgaenge.length > 0' :show-organisation='true')
