
.panel.panel-primary
  .panel-heading: .d-flex
    h3.panel-title.mr-3 Umsatzbilanz
    ul.nav.nav-pills.line-height-1.flex-grow-1
      li.nav-item(:class="{active: tab == 1}")
        a.nav-link(@click='tab = 1')
          |2 Jahre
      li.nav-item(:class="{active: tab == 2}")
        a.nav-link(@click='tab = 2')
          |BSC Jahr

  highcharts(v-if='chartData' :options="chart")
  div(v-if='isLoading' style='height: 400px')
    .text-center: i.fa.fa-spinner.fa-spin.fa-3x
