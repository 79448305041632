
div(style="min-height: 300px; transition: height 0.5s ease-in")
  .alert.alert-danger(v-if="error")
    | {{ error }}
  .panel-body.text-center(v-if="isLoading")
    PulseLoader(:loading="isLoading")
  div(v-else)
    .d-flex.justify-content-between
      .btn-group
        button.btn.btn-sm.btn-outline-dark(v-for='cm in communityManagers' @click='changeCm(cm)' :class="{ 'active': cm.id == currentCm }")
          | {{ cm.ldap_login }}
      .btn-group
        button.btn.btn-sm.btn-outline-dark.float-right(
          @click.prevent="problemMode = !problemMode",
          :class="{ 'active': problemMode }"
        )
          | Probleme
        button.btn.btn-sm.btn-outline-dark.float-right(
          @click.prevent="cmReviewMode = !cmReviewMode",
          :class="{ 'active': cmReviewMode }"
        )
          | CM-Review
    BTable(
      :items="organisationsWithGitlabIssues",
      :fields="fields",
      :sort-by.sync="sortBy",
      :sort-desc.sync="sortDesc"
      ref="table"
    )
      template(v-slot:cell(name)="row")
        a(:href="'/organisationen/' + row.item.id")
          | {{ row.item.name }}
        .d-flex.align-items-center(style='gap: 5px;')
          div: .btn-group
            a.btn.btn-xs.btn-outline-dark(:href='"https://login.empfehlungsbund.de/sessions/switch?user=" + row.item.username' target="_blank" v-if="row.item.username")
              | EB
            a.btn.btn-xs.btn-outline-dark(:href='"https://bms.empfehlungsbund.de/admin/switch_user?organisation=" + row.item.username' target="_blank" v-if="row.item.username && row.item.bmsType == 'ebms'")
              | BMS
          kbd.text-dark.bg-secondary
            | {{ row.item.username }}
        div.bg-danger.p-1.text-white.mt-1.rounded.small(v-if='problemMode')
          small.text-center.d-block(style='color: #fbb')
            |Probleme:
          ul.pl-3.m-0.line-height-1.d-flex.flex-column.gap-1
            li.m-0(v-for='problem in row.item.patientenakte.problems')
              | {{ problem }}


      template(v-slot:cell(mitarbeiterstufe.from)="{ item }")
        | {{ item.mitarbeiterstufe ? item.mitarbeiterstufe.level : '' }}

      template(v-slot:cell(bmsType)="{ item, value }")
        div(v-if="value == 'EBMS'")
          img(:src='bmsIcon' style='height: 1.2rem; width: 1.2rem')
        div(v-else)
          | {{ value }}

        small.text-muted(v-if='item.bmsType == "other_bms" && item.bmsName')
          | {{ item.bmsName }}
        div
          small(title="Bewerbungsmethode" v-b-tooltip v-if='item.patientenakte && item.patientenakte.applicationMethod && item.patientenakte.applicationMethod != "ebms"')
            | {{ item.patientenakte.applicationMethod }}

      template(v-slot:cell(weight)="{ item }")
        status-cell(:item="item")

      template(v-slot:cell(customerRelation)="{ item }")
        customer-relation-cell(:item="item")

      template(v-slot:cell(cmReview.reviewgespraech.value)="{ item }")
        review-cell(:item="item")

      template(v-slot:cell(cmReview.smartclickOhneCode.value)="{ item }")
        smartclick-cell(:item="item")

      template(v-slot:cell(patientenakte.applicationsLast12Months)="{ item }")
        statistic-cell(:item="item" v-if='item.patientenakte')

      template(v-slot:cell(patientenakte.hiredWithEb.last12Months)="{ item }")
        div(v-if='item.patientenakte')
          | {{ item.patientenakte.hiredWithEb.last12Months }}

          div: small.text-muted(v-b-tooltip title="Ausgefüllte Statistik Monate")
            | {{ item.cmReview.statistikAusgefuellt.value }} / 12
      template(v-slot:cell(patientenakte.jobImport)="{ item }")
        job-import-cell(:item="item")
