
<div class="partner-table">
  <SortableTable
    v-slot="slotProps"
    :headlines="headlines"
    :entries="mappedOrganisations"
  >
    <PartnerTableRow
      v-for="organisation in slotProps.entries"
      :key="organisation.ID"
      :organisation="organisation"
      :services="services"
    />
  </SortableTable>
</div>
