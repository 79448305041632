
<div>
  <ul class="nav nav-tabs">
    <li class='nav-item'>
      <a class="label-tab nav-link">{{ label }}</a>
    </li>
    <li class='nav-item' :class="{ active: filter == null }">
      <a class='nav-link' @click="setFilter(null)">
        Alle <span class="badge"> {{ incidents.length }} </span>
      </a>
    </li>
    <li class='nav-item' :class="{ active: filter == 'urgent' }">
      <a class='nav-link' @click="setFilter('urgent')">
        Dringend
        <span class="badge">
          {{ incidents.filter(s => s.urgent).length }}
        </span>
      </a>
    </li>
    <li class='nav-item' :class="{ active: filter == 'direct_message' }">
      <a class='nav-link' @click="setFilter('direct_message')">
        <i class="fa fa-fw fa-envelope-o" title="Direkt-Nachrichten"></i>
        <span class="badge">
          {{
            incidents.filter(s => s.incident_type == "direct_message").length
          }}
        </span>
      </a>
    </li>
    <li class='nav-item' :class="{ active: filter == 'measure' }">
      <a class='nav-link' @click="setFilter('measure')">
        <i class="fa fa-fw fa-thumb-tack" title="Maßnahmen"></i>
        <span class="badge">
          {{ incidents.filter(s => s.incident_type == "measure").length }}
        </span>
      </a>
    </li>
    <li class='nav-item' :class="{ active: filter == 'background_check' }">
      <a class='nav-link' @click="setFilter('background_check')">
        <i class="fa fa-fw fa-bug" title="Hintergrund Prüfungen"></i>
        <span class="badge">
          {{
            incidents.filter(s => s.incident_type == "background_check")
              .length
          }}
        </span>
      </a>
    </li>
    <li class='nav-item' :class="{ active: filter == 'communication_recommendation' }">
      <a class='nav-link' @click="setFilter('communication_recommendation')">
        <i class="fa fa-fw fa-comments" title="Review/Telefongespräch vereinbaren"></i>
        <span class="badge">
          {{
            incidents.filter(s => s.incident_type == "communication_recommendation")
              .length
          }}
        </span>
      </a>
    </li>
  </ul>
  <div class="list-group">
    <div
      v-for="group in limitedGroups"
      :key="group.organisation.ID"
      class="list-group-item py-0"
    >
      <strong>
        <a :href="'/organisationen/' + group.organisation.ID" target="_blank">
          {{ group.organisation.Bezeichnung }}
        </a>
      </strong>
      <table class="table table-sm">
        <IncidentRow
          v-for="incident in visibleItems(group.items)"
          :key="incident.id"
          :incident="incident"
          @remove="removeIncident"
        />
      </table>
    </div>
    <div
      v-if="!showAll && sortedIncidentGroups.length > limitedGroups.length"
    >
      <a @click="showAll = true" class="btn btn-link">
        Alle {{ sortedIncidentGroups.length }} Firmen mit Incidents anzeigen
      </a>
    </div>
  </div>
</div>
