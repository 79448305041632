
div
  .row
    .col-4
      .btn-group.select-all-box
        button.btn.btn-sm(@click='selectAll("recipient_status", statusOptions)')
          i.fa.fa-check-square-o
        button.btn.btn-sm(@click='unselectAll("recipient_status")')
          i.fa.fa-square-o
      b-form-group(label='Status des Eingeladenen')
        b-form-checkbox-group(
          v-model='filter.recipient_status'
          :options='statusOptions'
          stacked
        )
    .col-4
      b-form-group(label='Vorherige Teilnehmer ausblenden' description="(Vorgangstyp: 'Organisation - hat am Community-Treffen teilgenommen' organisation_ct_participant)")
        b-form-checkbox(v-model='filter.hide_previous_participants')
          |Ausblenden, falls bereits in den letzten 365 Tagen an irgendeinem Event <strong>teilgenommen</strong> hat
      button.btn.btn-primary.btn-block(@click.prevent='refresh')
        |Laden

  div(v-if='isLoading')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center

  div(v-if='isSaving')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center
    |wird gespeichert...

  recipient-table(v-model='selected' v-if='emails.length > 0' :emails='emails')

  div.alert.alert-info(v-else-if='selected.length > 0')
    | Anzahl ausgewählter Kontakte aus vorheriger Auswahl: {{ selected.length }}
    br
    small Zum Löschen einfach erneut Kontakte auswählen.

