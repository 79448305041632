
div
  pager(:pages='pages')
    template(v-slot:page(1))
      form
        b-form-group(label='Interner Titel' description='Interner Name der Kampagne')
          input.form-control(type="text" placeholder="Interner Titel der Kampagne" v-model="mailing.title" required)
        .row
          .col
            b-form-group(label='Absender (E-Mail)')
              b-form-select(v-model='form.from_email' :options='allowedFrom')
          .col
            b-form-group(label='Absender (Name)')
              b-form-input(v-model='form.from_display_name')
        b-form-group(label='BCC')
          b-form-input(v-model='form.bcc' type='email')

        b-form-group(label='Betreff' description='KEINE automatischen Ersetzungen hier')
          input.form-control(type="text" placeholder="Betreff (Keine Ersetzungen)" v-model="mailing.subject" required name='subject')

        editor(
          v-model='mailing.body'
          :placeholders='placeholders'
          :templateUrl='templateUrl'
        )
        hr

        attachments-field(v-model='uploads')

    template(v-slot:page(2))
      community-workshop-recipient-step(
        v-if='relatedEvent'
        v-model='form.filter' @count='recipient_count = arguments[0]' :mailing='form'
      )
      recipient-selector-step(
        v-else
        v-model='form.filter' @count='recipient_count = arguments[0]' :mailing='form'
      )

    template(v-slot:page(3))
      delivery-step(v-model='form')

    template(v-slot:last)
      button.btn.btn-primary.btn-lg(@click='deliver' :disabled='delivering')
        |E-Mail an {{ recipient_count }} Empfänger senden

