
div
  csv-import(:mailing='mailing' @refresh='importFromCsv')

  .row(v-if='organisationTypeOptions.length > 0')
    .col-sm-4
      strong Organisation

      b-form-group(label='Organisationstypen')
        .btn-group.select-all-box
          button.btn.btn-sm(@click='selectAll("organisation_types", organisationTypeOptions)')
            i.fa.fa-check-square-o
          button.btn.btn-sm(@click='unselectAll("organisation_types")')
            i.fa.fa-square-o
        b-form-checkbox-group(v-model='filter.organisation_types', :options='organisationTypeOptions' stacked)

      b-form-group(label='Mitgliedsstatus')
        b-form-checkbox-group(v-model='filter.membership_status', :options='membershipStatusOptions')

      b-form-group(label='Cluster')
        b-form-checkbox-group(
          v-model='filter.cluster',
          :options='clusterOptions'
        )
      b-form-group(label='Anbindung')
        b-form-checkbox-group(
          v-model='filter.bms',
          :options='bmsOptions'
        )

    .col-sm-4
      strong Standorte
      b-form-group(label='Standorte' description='Kommagetrennt mehrere')
        b-form-input(type='text' v-model='filter.location')
      b-form-group(label='Radius (km)')
        b-form-input(type='number' v-model='filter.radius')
      b-form-group(label='Standorte verwenden von:')
        b-form-radio(v-model='filter.standorte_verwenden' value='person') Person muss am Standort arbeiten
        b-form-radio(v-model='filter.standorte_verwenden' value='organisation') Min. 1 Person der Firma muss am Standort arbeiten
      br
      br
      button.btn.btn-primary.btn-block(@click.prevent='refresh') Laden
    .col-sm-4
      strong Personen
      b-form-group(label='Personentypen')
        .btn-group.select-all-box
          button.btn.btn-sm(@click='selectAll("personentyp", personentypOptions)')
            i.fa.fa-check-square-o
          button.btn.btn-sm(@click='unselectAll("personentyp")')
            i.fa.fa-square-o
        b-form-checkbox-group(v-model='filter.personentyp', :options='personentypOptions')
      b-form-group(label='Kontakterlaubnis')
        b-form-checkbox-group(v-model='filter.contact_status', :options='contactStatusOptions')
      b-form-group(label='Empfehlungsbund-Kontakt')
        b-form-checkbox(v-model='filter.person_empfehlungsbund_contact')
      b-form-group(label='Vorherige Kommunikation')
        b-form-checkbox-group(v-model='filter.communication_response_status' :options='communicationResponseOptions')


  div(v-if='isLoading')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center

  div(v-if='isSaving')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center
    |wird gespeichert...

  recipient-table(v-model='selected' v-if='emails.length > 0' :emails='emails')

  div(v-if='isSaving')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center

  div.alert.alert-info(v-else-if='selected.length > 0')
    | Anzahl ausgewählter Kontakte aus vorheriger Auswahl: {{ selected.length }}
    br
    small Zum Löschen einfach erneut Kontakte auswählen.

