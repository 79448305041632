
div
  div(v-if='step == 0')
    h4 1a. Firma auswählen oder anlegen

    input.form-control(v-model='form.company' placeholder='Firmenname auswählen' @keyup='search')

    div.list-group(v-if='organisationLoading')
      .list-group-item: i Lade Firmen ...

    div.list-group(v-else)
      .list-group-item(v-if='organisationsSearchResults.length > 0'): strong Gefundene Firmen (auswählen um fortzufahren)
      .list-group-item(v-for='o in organisationsSearchResults' @click='selectCompany(o)')
        | {{ o.Bezeichnung }} ({{o.ID}})
        br
        small.text-muted
          |{{ o.personen.length}} Personen, angelegt am {{o.created_at }}
      .list-group-item(v-if='organisationsSearchResults.length == 0'): i Keine Matches gefunden

    div.my-3
      h4 1b. Neue Firma
      button.btn.btn-primary(@click='newCompany')
        |Neue Firma anlegen

    div.my-3(v-if='message.weaver_organisation')
      h4 1c. Weaver Firma verwenden
      div.list-group
        .list-group-item(@click='step = 10')
          | {{ message.weaver_organisation.name }}

  div(v-if='step == 1')
    h4 2. Neue Firma anlegen
    create-organisation(:name='form.company' @save='selectCompany' :website="this.message.parsed_data ? this.message.parsed_data.website : null")

  div(v-if='step == 10')
    h4 2. Weaver Firma wird übertragen
    create-weaver-organisation(:organisation-id='message.weaver_organisation.id' @save='selectCompany')

  div(v-if='step == 2 || step == 3')
    h4 2. Person auswählen oder anlegen

    blockquote.blockquote
      strong
        |{{ message.parsed_data.name }}
      |
      |
      span(v-if='message.parsed_data.xing_url')
        a(:href='message.parsed_data.xing_url' target='_blank' rel='noopener')
          |XING-Profil
      div(v-if='message.organisation')
        |Aktuelle Organisation: {{message.organisation.Bezeichnung}}

    .panel.panel-default(v-if='message.person_id && organisation.ID != message.organisation_id')
      .panel-heading
        |Person umziehen
      .panel-body
        |Person ist bereits einer Firma zugeordnet.
        dl
          dt Umziehen von:
          dd
            | {{ message.organisation.Bezeichnung }}
          dt ...nach:
          dd
            | {{ organisation.Bezeichnung }}
        | Umziehen archiviert die vorhande Person in der alten Firma und legt eine neue Person in der neuen Firma an und verlinkt die beiden.
      .panel-footer
        button.btn.btn-primary(@click='save')
          |Existierende Person umziehen

    .panel.panel-default(v-if='message.person_id && organisation.ID == message.organisation_id')
      .panel-heading
        |Daten aus Lead übertragen
      .panel-body
        |Person ist bereits angelegt und Organisation unverändert. Jetzt ggf. fehlende Daten aus dem Lead übertragen.
      .panel-footer
        button.btn.btn-primary(@click='save')
          |Speichern

    .panel.panel-default
      .panel-heading  Mit existierender Person dieser Firma mergen:
      .list-group(v-if='people.length > 0')
        .list-group-item.clearfix(v-for='o in people')
          .float-right.pull-right
            button.btn.btn-primary.btn-sm(@click.prevent='selectPerson(o)')
              |Merge
          | {{ o.Anrede }} {{ o.Vorname }} {{ o.Nachname }}
          br
          small.text-muted
            | {{ o.Email }}

      .panel-body(v-if='people.length == 0'): i Keine Matches gefunden
    .panel.panel-default
      .panel-heading Neue Person anlegen
      .panel-body
        button.btn.btn-primary(@click='newPerson')
          |Neue Person anlegen

    .panel.panel-default(v-if='step == 3'): .panel-body
      .form-group
        label.control-label
          | Vorname
          input.form-control(v-model='form.first_name' placeholder='Vorname')
      .form-group
        label.control-label
          | Nachname
          input.form-control(v-model='form.last_name' placeholder='Nachname')
      .form-group
        .control-label
          | Anrede
        div: label
          input(v-model='form.salutation' type='radio' name='salutation' value='Herr')
          |
          |Herr
        div: label
          input(v-model='form.salutation' type='radio' name='salutation' value='Frau')
          |
          |Frau
      .form-group
      .form-group
        label.control-label
          | Position
          input.form-control(v-model='form.position' placeholder='Position')
        label.control-label
          | Akadem. Titel
          input.form-control(v-model='form.title' placeholder='Titel')
        label.control-label
          | E-Mail
          input.form-control(v-model='form.email' placeholder='E-Mail')

        div.text-muted(v-if='organisation && organisation.email_format')
          span
            |E-Mail-Format der Firma: <code>{{organisation.email_format}}</code>
          div: strong Leer lassen, dann wird E-Mail automatisch aus Vor und Nachnamen erzeugt

      .form-group
        label.control-label
          | Telefon
          input.form-control(v-model='form.phone' placeholder='Telefon')

      .form-group
        label.control-label
          | Standort
          div(v-for='standort in organisation.standorte')
            label
              input(type='radio' v-model='form.location_id' :value='standort.ID')
              |
              |{{standort.Stadt }}


      button.btn.btn-primary(@click='save')
        |Speichern

