
div
  reminder-panel(:vorgaenge='reminder')
  lazyload: customer-panel(:services='services' :communityManagers='communityManagers')
  lazyload: recently-imported-mails
  lazyload: leads-panel(:community-managers='communityManagers' :community-manager-id='communityManagerId')
  lazyload: renewable-panel
  lazyload: rechnungen-panel
  lazyload: incident-panel
  .row
    .col-md-6
      lazyload: umsatz-chart
    .col-md-6
      lazyload: review-chart
  .row
    .col-md-6
      lazyload: umsatzbilanz-chart
    .col-md-6
      lazyload: handlungskompetenz-panel
