
div(v-if='organisation')
  b-form(@submit="onSubmit" @reset="onReset")
    template(v-if="organisation.organisation_addresses.length > 0")
      b Welche Adressen sollen übernommen werden?
      .list-group.mt-3
        a.list-group-item.list-group-item-action.d-flex.justify-content-between(href="#" v-for="address in organisation.organisation_addresses" :key="address.id" @click='toggleAddress(address.id)' :class="{'bg-light': form.selected_addresses.includes(address.id)}")
          | {{ address.full }}
          template(v-if="form.selected_addresses.includes(address.id)")
            i.fa.fa-check-circle(style="font-size: 1.2rem;")
          template(v-else)
            i.fa.fa-circle-o(style="font-size: 1.2rem;")

    template(v-if="organisation.people.length > 0")
      hr
      b Welche Ansprechpartner sollen übernommen werden?
      .list-group.mt-3
        a.list-group-item.list-group-item-action.d-flex.justify-content-between(href="#" v-for="person in organisation.people" :key="person.id" @click='togglePerson(person.id)' :class="{'bg-light': form.selected_people.includes(person.id)}")
          | {{ person.full }}
          |
          | {{ person.email }}
          |
          | {{ person.telephone }}
          template(v-if="form.selected_people.includes(person.id)")
            i.fa.fa-check-circle(style="font-size: 1.2rem;")
          template(v-else)
            i.fa.fa-circle-o(style="font-size: 1.2rem;")
    hr
    b Allgemeine Daten
    b-form-group(label="Firmenname:")
      b-form-input(v-model="form.name" required)
    b-form-group(label="Webseite:")
      b-form-input(v-model="form.url")
    b-form-group(label='Cluster:')
      b-form-checkbox-group(v-model='form.selected_cluster' :options='clusterOptions' stacked)

    button.mt-3.btn.btn-primary.btn-lg.float-right(type='submit')
      i.mdi.mdi-zip-disk.mr-1
      |Anlegen

