
div
  .panel.panel-primary
    .panel-heading
      h3.panel-title
        a(data-toggle="collapse" href="#leads")
          | Posteingang / Leads
      div
        ul.nav.nav-pills.line-height-1
          li.nav-item()
            a.nav-link(href="#" @click.prevent="recipientFilter = 'all'" :class="{ active: recipientFilter === 'all' }")
              | Alle Quellen
          li.nav-item(v-for="recipient in recipients")
            a.nav-link(href="#" @click.prevent="recipientFilter = recipient", :class="{ active: recipientFilter == recipient }")
              | {{ recipient.replace('xing-', '').replace(/@.*/, '') }}
              span.badge.badge-dark
                | {{ leads.filter((e) => e.recipient === recipient).length }}
        ul.nav.nav-pills.line-height-1
          li.nav-item(:class="{ active: !assigneeFilter }")
            a.nav-link(href="#" @click.prevent="assigneeFilter = null")
              | Alle CMs
          li.nav-item(v-for='item in communityManagerCounts' :class="{ active: item.communityManager && item.communityManager.id === assigneeFilter }")
            a.nav-link(href='#' @click.prevent='assigneeFilter = item.communityManager.id')
              span(v-if='item.communityManager')
                | {{ item.communityManager.ldap_login }}
              span(v-else)
                | ??
              span.badge.badge-dark
                | {{ item.count }}


    #leads.collapse.show: .list-group
      lead-row(
        v-for="message in filteredIncidents",
        :message="message",
        :key="message.id"
        @update="reload",
        :communityManagers="communityManagers"
      )
