
.card.m-0.p-1
  .d-flex.justify-content-between.gap-1.align-items-start
    div
      .vorgang-title
        | {{ vorgang.organisation.name }}

    div.flex-column.d-flex.align-items-end
      small.text-muted
        | {{ date }}
  div.text-muted
    | {{ vorgang.type.name }}
    span.ml-2(v-if='vorgang.comment' v-b-tooltip='vorgang.comment')
      i.fa.fa-comment-o.fa-fw

  .d-flex.justify-content-between
    div.d-flex.align-items-center.gap-1
      .d-flex.flex-column
        akquisegefuehl(v-if='vorgang.akquisegefuehl' :akquisegefuehl='vorgang.akquisegefuehl')
        small.text-muted(v-if=' vorgang.organisation.potentiellesUmsatzvolumen ')
          | {{ vorgang.organisation.potentiellesUmsatzvolumen }}€
      span.badge.badge-dark(v-if='vorgang.reminder' title='Erinnerung gesetzt')
        i.fa-clock.fa-fw.fa
        |
        | {{ vorgang.reminder | moment("L") }}
    div.d-flex.align-items-center.gap-1
      community-manager-chip(:communityManager='communityManager' v-if='communityManager')
      .chip(v-else)
        | {{ vorgang.communityManagerName }}
      person-chip-gql(:person='vorgang.personen[0]' v-if='vorgang.personen[0]')
  .vorgang-hovering-buttons: .btn-group
    a.js-modal.btn.btn-sm.btn-primary(
      :href="vorgangUrl"
      title="Nachfolgevorgang anlegen und Erinnerung löschen, falls vorhanden"
      v-b-tooltip
    )
      i.fa.fa-plus.fa-fw

    a.btn.btn-sm.btn-dark(:href="crmUrl" title='Akte' v-b-tooltip target='_blank')
      |CRM
    a.btn.btn-sm.btn-default.js-modal(v-if="vorgang.canEdit", :href="'/vorgaenge/' + vorgang.id + '/edit'" title="Bearbeiten" v-b-tooltip)
      i.fa.fa-pencil-square-o.fa-fw

    template(v-if="vorgang.canRemoveReminder")
      a.btn.btn-sm.btn-default(@click="deleteReminder" title='Termin Erinnerung löschen' v-b-tooltip)
        i.fa.fa-calendar-times-o.fa-fw
    template(v-else)
      a.btn.btn-outline-dark(:href="vorgangUrl")
        |Neuer Vorgang

