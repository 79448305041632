
div
  div.list-group
    div.list-group-item(
      v-for="cm in communityManagers",
      :class="{ active: community_manager && cm.id === community_manager.id }"
    )
      div(style="width: 30px; margin-right: 10px")
        img.img-circle(:src="cm.image", v-if="cm.image")
      div(style='flex-grow: 1')
        | {{ cm.name }}
      button.btn.btn-outline-primary.btn-sm(
        @click="select(cm)",
        v-if="!community_manager || cm.id != community_manager.id"
      )
        | Zuweisen
