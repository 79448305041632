
<form>
  <div class="form-group">
    <textarea
      v-model="reason"
      class="form-control"
      required
      placeholder="Grund für Nichtbearbeitung"
    ></textarea>
  </div>
  <DatePicker
    v-model="muted_until"
    label="Incident automatisch muten bis"
    :initial-value="muted_until"
    :max="180"
    min="today"
    :only-date="true"
  />
  <button
    class="btn btn-primary"
    :disabled="!isValid"
    @click.prevent.stop="submitMuting"
  >
    Incident muten
  </button>
</form>
