
div
  div(v-if='item.patientenakte.jobImport')
    small(title="Stellen online" v-b-tooltip)
      |{{ item.patientenakte.jobsCurrentlyOnline }}({{ item.patientenakte.jobImport.jobsOnline }})
    br
    span(v-if='item.patientenakte.jobsCurrentlyOnline == 0 || item.patientenakte.jobImport.jobsOnline == 0')
      span.badge.badge-danger(title='Keine Stellen online' v-b-tooltip)
        |Keine Stellen online!
    div(v-if='item.patientenakte.jobImport.errorCount != 0')
      button.btn-unstyled(@click='errorsModalOpen = true')
        span.badge.badge-danger(title='Feedimport Fehler' v-b-tooltip)
          | Jobs mit Fehlern:
          |
          |{{ item.patientenakte.jobImport.errorCount }}
    div(v-if='item.patientenakte.jobImport.warningCount != 0')
      button.btn-unstyled(@click='warningsModalOpen = true')
        span.badge.badge-warning(title='Feedimport Warnungen' v-b-tooltip)
          | Jobs mit Warnungen:
          |
          |{{ item.patientenakte.jobImport.warningCount }}
    div
      span.badge.badge-primary(title='Wie gelangen die Stellen zu uns?' v-b-tooltip)
        |{{ primaryChannelText }}

  button.btn-unstyled(v-if='item.patientenakte.crawlerError' @click='crawlerErrorModalOpen = true')
    span.badge.badge-danger(title='Crawler Fehler' v-b-tooltip)
      i.fa.fa-exclamation-triangle
      |
      |Crawler Fehler / Jobs leer
  b-modal(v-model="warningsModalOpen" :hide-footer='true' v-if='item.patientenakte.jobImport.feeds && item.patientenakte.jobImport.feeds.length > 0 && item.patientenakte.jobImport.warningCount > 0' size='xl')
    template(v-slot:modal-title)
      | Alle Warnungen
    dl
      template(v-for='feed in item.patientenakte.jobImport.feeds.filter(feed => feed.warnings.length > 0)')
        dt
          |{{ feed.url }}
        dd
          div(v-for='warning in feed.warnings')
            |{{warning.label}}: {{ warning.count }}
  b-modal(v-model="errorsModalOpen" :hide-footer='true' v-if='item.patientenakte.jobImport.feeds && item.patientenakte.jobImport.feeds.length > 0 && item.patientenakte.jobImport.errorCount > 0' size='xl')
    template(v-slot:modal-title)
      | Alle Fehler
    dl
      template(v-for='feed in item.patientenakte.jobImport.feeds.filter(feed => feed.errors.length > 0)')
        dt
          |{{ feed.url }}
        dd
          div(v-for='error in feed.errors')
            |{{error.label}}: {{ error.count }}

  b-modal(v-model='crawlerErrorModalOpen' :hide-footer='true' v-if='item.patientenakte.crawlerError' size='xl')
    dl
      dt Mapping:
      dd
        |{{ item.patientenakte.crawlerError.mapping }}
      dt Anzahl Stellen im Mapping/Crawler erfolgreich gefunden:
      dd
        |{{ item.patientenakte.crawlerError.job_count }}
      dt Nachrichten
      dd
        div(v-for='message in item.patientenakte.crawlerError.messages')
          |{{ message[1] }}
      dt Log:
      dd
        pre.pre
          |{{ item.patientenakte.crawlerError.last_logs.join() }}
      dt Link:
      dd
        a(:href='item.patientenakte.crawlerError.url' target='_blank')
          |Link zum Mapping-Admin


