
div
  .card.overflow-hidden.wizard-header: .row.bs-wizard
    .col-sm.bs-wizard-step(v-for='(page, i) in pages' :class='{complete: i < currentPage - 1, active: i == currentPage - 1 }')
      .text-center.bs-wizard-stepnum
        | {{ page.title }}
      .progress: .progress-bar
      a.bs-wizard-dot.bs-clickable(v-if='i < maxPage' @click.prevent='currentPage = i + 1' style='cursor: pointer')
      a.bs-wizard-dot(v-else)
  .bs-wizard-content: slide-x-left-transition(:duration="300" :delay="400" :group='true')
    .card(v-if='i + 1 == currentPage' :key='i' v-for='(page, i) in pages')
      .card-header.bg-dark.text-white
        h3.card-title
          |{{ page.title }}
      .card-body
        slot(:name="'page(' + (i + 1) +')'" v-bind:page="page" :index='i + 1')
      .card-footer
        slot(name='footer' v-bind:page='page' :index='i + 1' :goNext='() => goNext(i + 1)')
          button.btn.btn-link(@click.prevent='goBack(i + 1)' v-if='i > 0')
            |zurück
          template(v-if='i == pages.length - 1')
            slot(name='last')
          template(v-else)
            button.btn.btn-primary.btn-lg(@click.prevent='page.isValid && goNext(i + 1)' :disabled='!page.isValid')
              | weiter

