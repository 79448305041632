
.panel.panel-primary
  .panel-heading
    .d-flex
      h3.panel-title.mr-2: a(@click.prevent='show = !show' href='#')
        |Rechnungen deiner Partner
      ul.nav.nav-pills.line-height-1.flex-grow-1
        li.nav-item(:class="{active: tab == 1}")
          a.nav-link(@click='tab = 1')
            |Überfällig
            |
            span.badge.badge-dark(v-if='due.length > 0')
              |{{due.length}}

        li.nav-item(:class="{active: tab == 2}")
          a.nav-link(@click='tab = 2')
            |Unbezahlt
            |
            span.badge.badge-dark(v-if='rechnungen.length > 0')
              |{{ rechnungen.length }}

        li.nav-item(:class='{active: tab == 3}')
          a.nav-link(@click='tab = 3')
            |Zahlungserinnerungen
            |
            span.badge.badge-dark(v-if='mahnungen.length > 0')
              |{{ mahnungen.length }}
      div(v-if='isAdmin')
        .d-flex.align-items-baseline
          span.badge.badge-danger.mr-2 Rechnungsadmin:
          .btn-group
            a.btn.btn-dark.btn-xs(@click='onlyMy = false' v-if='onlyMy')
              |Alle Rechnungen anzeigen
            a.btn.btn-outline-dark.btn-xs(href='/rechnungen/demand_all' class='btn btn-outline-primary')
              |Alle Mahnungen verschicken
            a.btn.btn-outline-dark.btn-xs(href='/print_month' class='btn btn-outline-primary')
              |Monat drucken

  rechnungen-table(:value='showRechnungen' v-if='showRechnungen.length > 0 && show')

