
.card.mb-4(style='max-width: 500px')
  .card-header
    h5.card-title
      | CSV-Import
  .card-body
    ul
      li Mindestens Spalte "Email/E-Mail/email" muss vorhanden sein
      li Spalten "Firma", "Name" (Kompletter Anzeigename/Nachname) und "Anrede" optional
      li Format: Mit Semikolon getrennt, mit Anführungszeichen umschlossen
  .card-footer
    b-form-file(v-if="!isUploading" type="file" accept=".csv" placeholder="Choose a file or drop it here..." @input="uploadCsv")
    div(v-if="isUploading")
      i.fa.fa-spinner.fa-spin.fa-3x.text-center
