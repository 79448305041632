
.pdfvuer
  .pdfvuer-toolbar(v-if='false')
    .btn-group
      a.btn.btn-sm.btn-default(@click="zoomMinus")
        i.left.chevron.icon
        | Zoom -
      span.btn.btn-dark.btn.btn-sm.disabled
        | {{ Math.round(formattedZoom) }} %
      a.btn.btn-sm.btn-default(@click="zoomPlus")
        | Zoom +
        i.right.chevron.icon

  vue-pdf-embed(
    :source="url"
    ref='pdfs'
    @link-clicked="handle_pdf_link"
    :annotation="true"
  )
    template(slot="loading")
      | loading content here...
  .alert.alert-danger.m-5(v-if="error")
    | Error loading PDF:
    | {{ error.message }}
    br
    | {{ error.details }}
