
div(style="min-height: 300px; transition: height 0.5s ease-in")
  .d-flex.justify-content-between.align-items-center
    .d-flex.mx-3(style='gap:5px')
      div
        small Organisationen:
        b-form-checkbox(v-model='filter.myOrganisation')
          |Meine Organisation
          span.mx-1.d-inline-block( title='Organisationen die mir oder meinem Team zugewiesen sind' v-b-tooltip)
            i.fa.fa-question-circle
        b-form-checkbox(v-model='filter.otherOrganisation')
          |Andere Organisation
      div
        small Vorgänge:
        b-form-checkbox(v-model='filter.vorgangByMy')
          |Letzter Vorgang von mir
          span.mx-1.d-inline-block(title='Der allerletzte (angezeigte) Akquisevorgang mit der Organisation ist von mir' v-b-tooltip)
            i.fa.fa-question-circle
        b-form-checkbox(v-if='currentCommunityManagerTeamIds.length > 0' v-model='filter.anyVorgangByMyTeam' )
          |Letzter Vorgang von meinem Team
          span.mx-1.d-inline-block(v-b-tooltip title='der letzte Akquisevorgang ist von einem Teammitglied meines Akquiseteams')
            i.fa.fa-question-circle
        b-form-checkbox(v-model='filter.anyVorgangByMy')
          |Irgendein Vorgang von mir
          span.mx-1.d-inline-block(v-b-tooltip title='Im Zeitraum rechts habe ich jemals einen Akquisevorgang mit dieser Organisation gehabt')
            i.fa.fa-question-circle
        b-form-checkbox(v-model='filter.vorgaengeByOthers')
          |Vorgänge von anderen


    div
      small
        |Sortierung
        span.mx-1.d-inline-block(v-b-tooltip title='Fällige Erinnerungen stehen immer oben, nicht fällige Erinnerung immer unten, ansonsten sortiert nach Datum des Vorgangs entsprechend der Option unten')
          i.fa.fa-question-circle
      nav.nav-pills.nav.m-2
        // sortOrder
        .nav-item
          a.nav-link(:class="{ active: sortBy === 'oldestFirst' }" @click="sortBy = 'oldestFirst'") Älteste zuerst
        .nav-item
          a.nav-link(:class="{ active: sortBy === 'newestFirst' }" @click="sortBy = 'newestFirst'") Neueste zuerst

    div
      small Letzter Akquisevorgang:
      nav.nav-pills.nav.m-2
        .nav-item
          a.nav-link(:class="{ active: filter.date === 'lastYear' }" @click="filter.date = 'lastYear'") Letztes Jahr
        .nav-item
          a.nav-link(:class="{ active: filter.date === 'last2Years' }" @click="filter.date = 'last2Years'") Letzte 2 Jahre
        .nav-item
          a.nav-link(:class="{ active: filter.date === 'last5Years' }" @click="filter.date = 'last5Years'") Letzte 5 Jahre



  .alert.alert-danger(v-if="error")
    | {{ error }}
  .panel-body.text-center(v-if="isLoading")
    PulseLoader(:loading="isLoading")
  div(v-else)

    .d-grid
      .akquise-card(v-for='(items, index) in filteredItems' :key="index")
        h2.small.text-center.sticky-headline
          |{{ translation[index] || index }}
          br
          small
            |Summe: {{ akquiseSum(items) }}

        .d-flex.flex-column(style='gap: 3px')
          akquise-organisation-card(v-for='(item, index) in items' :key="index" :vorgang="item")
