<template lang="pug">
.panel.panel-primary
  .panel-heading
    .d-flex
      h3.panel-title.mr-4: a(@click.prevent='show = !show' href='#')
        |Verlängerbare Mitglieder

      ul.nav.nav-pills.line-height-1.flex-grow-1
        li.nav-item(:class='{active: tab === 1}')
          a.nav-link(@click='tab = 1')
            |Verlängerbar (1-Monat)
            |
            span.badge.badge-dark
              | {{ nowRenewable.length }}
        li.nav-item(:class='{active: tab === 2}')
          a.nav-link(@click='tab = 2')
            |Alle
            |

            span.badge.badge-dark
              | {{ renewable.length }}

      div
        a.btn.btn-outline-dark.btn-sm(@click='showAll = true' v-if='isAdmin && !showAll')
          |Alle CMs
  div(v-if='isLoading' style='height: 400px')
    .text-center: i.fa.fa-spinner.fa-spin.fa-3x
  b-table(:items="showItems" :fields="fields" v-if='showItems.length > 0 && show' hover striped small table-class='mb-0')
    template(v-slot:cell(organisation.name)="{ item }")
      a(:href="'/organisationen/' + item.organisation.id")
        |{{ item.organisation.name }}

    template(v-slot:cell(price.netto)="{ item }")
      |{{ item.price.nettoFormatted }}
      div: small
        | {{ item.employeeLevel ? item.employeeLevel.level : '' }}

    template(v-slot:cell(actions)="{ item }")
      .btn-group.text-right
        a.btn.btn-sm.btn-outline-dark.js-modal(:href='"/angebote/" + item.id + "/edit"' v-if='item.canEdit')
          i.fa.fa-pencil-square-o.fa-fw
        a.btn.btn-sm.btn-outline-dark.js-modal(:href='"/angebote/" + item.id + "/cancellation/new"' v-if='item.canCancel')
          i.fa.fa-ban.fa-fw
        a.btn.btn-sm.btn-outline-primary.js-modal(:href='"/angebote/" + item.id + "/renewal/new"' v-if='item.canRenew')
          i.fa.fa-refresh.fa-fw
</template>

<script lang="ts">
import Vue from "vue"
import gql from "utils/graphql"
import { RenewableContractsQuery } from "types/graphql/requests"
import { BTable } from "bootstrap-vue"
import type { BvTableFieldArray } from "bootstrap-vue"

type Renewable = RenewableContractsQuery["renewable"][0]

export default Vue.extend({
  components: {
    BTable,
  },
  props: {},
  data() {
    return {
      tab: 1,
      isLoading: true,
      show: true,
      showAll: false,
      renewable: [] as Renewable[],
    }
  },
  computed: {
    fields(): BvTableFieldArray {
      return [
        {
          key: "organisation.name",
          label: "Organisation",
          sortable: true,
        },
        {
          key: "organisation.communityManager.ldapLogin",
          label: "Community-Manager",
          sortable: true,
        },
        {
          key: "nextServicePeriodBeginn",
          label: "Nächster Beginn",
          sortable: true,
          formatter: (value: string) => {
            return this.$moment(value).format("L")
          },
        },
        {
          key: "renewableSince",
          label: "Verlängerbar seit",
          sortable: true,
          formatter: (value: string) => {
            return this.$moment(value).format("L")
          },
        },
        {
          key: "price.netto",
          label: "Preis",
          sortable: true,
        },
        {
          key: "actions",
          label: "Aktionen",
        },
      ]
    },
    isAdmin() {
      return window.CurrentCm.rechnungsadmin
    },
    nowRenewable(): Renewable[] {
      return this.renewable.filter(
        (renewable: Renewable) =>
          renewable.nextServicePeriodBeginn &&
          new Date(renewable.nextServicePeriodBeginn).getTime() -
            30 * 3600 * 24 * 1000 <
            new Date().getTime()
      )
    },
    showItems(): Renewable[] {
      return this.tab === 1 ? this.nowRenewable : this.renewable
    },
  },
  watch: {
    isAdmin() {
      this.fetch()
    },
    showAll() {
      this.fetch()
    }
  },
  methods: {
    async fetch() {
      this.isLoading = true
      this.renewable = []
      const { renewable } = await gql.RenewableContracts({
        showMy: !this.showAll,
      })
      this.isLoading = false
      this.renewable = renewable
    },
  },
  mounted() {
    this.fetch()
  },
})
</script>
<style scoped>
.nav-item {
  cursor: pointer;
}
</style>
