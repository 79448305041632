
<div class="partner-table">
  <ul class="nav nav-tabs">
    <li class='nav-item' :class="{active: filterMode == 'all'}">
      <a class='nav-link' href="#" @click.prevent="filterMode='all'">Alle Firmen</a>
    </li>
    <li class='nav-item' :class="{active: filterMode == 'own'}">
      <a class='nav-link' href="#" @click.prevent="filterMode='own'">Eigene</a>
    </li>
    <li class='nav-item' :class="{active: filterMode == 'other'}">
      <a class='nav-link' href="#" @click.prevent="filterMode='other'">Fremd-Vorgänge</a>
    </li>
  </ul>
  <SortableTable
    v-slot="slotProps"
    :headlines="headlines"
    :entries="mappedOrganisations"
  >
    <TableRow
      v-for="organisation in slotProps.entries"
      :key="organisation.ID"
      :organisation="organisation"
    />
  </SortableTable>
</div>
