
.panel.panel-primary
  .panel-heading: .d-flex
    h3.panel-title.mr-4: a(@click.prevent='show = !show' href='#')
      |Gitlab-Issues (EBMS)
    ul.nav.nav-pills.line-height-1.flex-grow-1
      li.nav-item(:class='{active: tab == 0}')
        a.nav-link(@click='tab = 0')
          | Alle
          |
          span.badge.badge-dark
            | {{counts.open}}
      li.nav-item(:class='{active: tab == 1}')
        a.nav-link(@click='tab = 1')
          | Kontaktanfragen
          |
          span.badge.badge-dark
            | {{counts.eb}}
      li.nav-item(:class='{active: tab == 2}')
        a.nav-link(@click='tab = 2')
          | EBMS
          |
          span.badge.badge-dark
            | {{counts.bms}}

    ul.nav.nav-pills.line-height-1.flex-grow-1
      li.nav-item(:class='{active: visibility == "open"}')
        a.nav-link(@click.prevent='visibility = "open"' href='#')
          | Offen
          span.badge.badge-dark
            | {{counts.open}}
      li.nav-item(:class='{active: visibility == "closed"}')
        a.nav-link(@click.prevent='visibility = "closed"' href='#')
          | Geschlossen
          span.badge.badge-dark
            | {{counts.closed}}


  div(v-if='isLoading')
    pulse-loader.text-center.my-5(:loading="isLoading")

  table.table.table-sm(v-if='show')
    tbody
      tr(v-for='issue in showIssues')
        td
          a(:href="issue.url" target="_blank")
            | {{issue.title}}
        td
          | {{issue.created_at | moment("L")}}
        td: .d-flex.flex-wrap(style='gap: 3px')
          span.badge.badge-dark(v-for='label in issue.labels' v-if='!label.match(/customer:|Project:/)')
            | {{label.replace(/[A-Z]-|Type:/, '')}}

