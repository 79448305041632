
div
  b-form-file(
    v-show="!uploadInProgress"
    :accept="accept"
    @input="uploadFile"
    :placeholder="placeholder"
    :dropPlaceholder="dropPlaceholder"
    browse-text='Auswählen'
    :disabled="disabled"
  )
  .list-group(v-if="uploads.length > 0")
    .list-group-item(v-for="file in uploads")
      slot(name="progress" v-bind:file="file" v-bind:progress="progress")
        b-spinner(small)
        i.mdi.mdi-loading.fa-spin
        i Wird hochgeladen: {{file.file.filename }}
        br
        b-progress(:value="progress" :max="100" :animated="true" style="max-width: 200px; height: 2px")
      br
      br
