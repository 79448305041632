
div
  b-modal(v-model="showModal" size="lg" scrollable title="Firma zuweisen" hide-footer)
    form(@submit.prevent="search")
      .input-group
        input.form-control(type="text" placeholder="Suche nach Firma" v-model="query" @keydown.enter="search" autofocus)
        .input-group-append
          button.btn.btn-outline-dark(type="button" @click="search")
            i.mdi.mdi-magnify

    .list-group.mt-2.list-group-flush.search-list(data-target="crm-search.list")
      .list-group-item.list-group-item-action.d-flex.justify-content-between(v-for='org in foundOrganisations')
        div
          | {{ org.text}}
          br
          small.text-muted Aktueller CM: {{org.cm}}

        .btn-group
          button.btn.btn-sm.btn-outline-dark(@click.prevent="assign(org, false)")
            i.mdi.mdi-plus
            |ZUWEISEN
          button.btn.btn-sm.btn-outline-dark(@click.prevent="assign(org, true)")
            i.mdi.mdi-plus
            |ZUWEISEN und mich als CM setzen
      .list-group-item
        button.btn.btn-outline-primary(@click='showCreateCompanyModal = true; showModal = false')
          |Neue Firma anlegen

  div.alert.alert-success.p-1.m-0(v-if='assigned')
    | Firma erfolgreich zugewiesen
  button.btn.btn.btn-secondary.text-nowrap.mr-1(v-else @click.prevent="showModal = true")
    i.mdi.mdi-swap-horizontal.mr-1
    | CRM-Firma zuordnen

  create-organisation(
    v-model='showCreateCompanyModal'
    v-if='showCreateCompanyModal'
    :organisation='organisation'
    :cluster='clusters'
    @created='assigned = true; showCreateCompanyModal = false'
  )

