
div
  .d-flex.align-items-center.py-1.px-3
    b-form-checkbox-group#checkbox-group-1.checkbox-group(
      v-model="filter",
      :options="filterOptions",
      plain
    )
    input.form-control(placeholder='Suchbegriff' v-model='query' style='max-width: 400px')

  vorgaenge-table(v-model='paginatedItems' @input='fetch')

  button.btn.btn-block.btn-secondary(
    @click="showAll = true",
    v-if="!showAll && filteredItems.length > 3"
  )
    | Alle anzeigen ({{ filteredItems.length }})

  button.btn.btn-block.btn-secondary(
    @click="showAll = false",
    v-if="showAll"
  )
    | Weniger anzeigen
