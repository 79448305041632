
.panel.panel-primary
  .panel-heading
    .d-flex.justify-content-between(style='gap: 10px')
      h2.panel-title Themen
      ul.nav.nav-pills.line-height-1.flex-grow-1(style="gap: 2px")
        li.nav-item(:class="{ active: topicFilter == null }")
          a.nav-link(@click.prevent="topicFilter = null" href='#')
            | Alle
        li.nav-item(:class="{ active: topicFilter == thema }" v-for="(count, thema) in themaCounts")
          a.nav-link(@click.prevent="topicFilter = thema" href='#')
            | {{ thema }}
            span.badge.badge-dark.ml-1
              | {{ count }}

  b-table.table-sm(
    striped
    hover,
    :fields="fields",
    :items="paginatedItems"
    responsive
    primary-key="id",
    :tbody-transition-props="{ name: 'flip-list' }"
    show-empty,
    :sort-desc.sync="sortDesc",
    :sortBy.sync="sortBy"
    tbody-tr-class="flip-list-item"
  )
  button.btn.btn-block.btn-secondary(
    @click="showAll = true",
    v-if="!showAll && items.length > paginatedItems.length"
  )
    | Alle anzeigen ({{ items.length }})
