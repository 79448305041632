
<tr>
  <td>
    <div class="pull-right">
      <div class="btn-group quick-buttons">
        <a
          v-if="canCloseWithoutVorgang"
          v-b-tooltip
          title="Abhaken ohne Vorgang"
          class="btn btn-sm btn-success"
          @click="closeWithoutIncident"
        >
          <i class="fa-check-square-o fa fa-fw"></i>
        </a>
        <a
          v-if="canMute"
          v-b-tooltip="'Muten'"
          class="btn btn-sm btn-default"
          @click="openMuteModal"
        >
          <i class="fa-microphone-slash fa fa-fw"></i>
        </a>
        <a
          v-b-tooltip
          title="Vorgang anlegen und erledigen"
          class="btn btn-sm btn-primary"
          :href="incidentCompletUrl"
          target="_blank"
        >
          <i class="fa-play fa fa-fw"></i>
        </a>
      </div>
    </div>
    <span class="incident-type">
      <i class="fa fa-fw" :class="incidentTypeIcon"></i>
    </span>
    <a @click.prevent="modalOpen = true" href="#"> {{ incident.subject }} </a>
    <br />
    <small class="text-muted line-height-1 d-block" @click="modalOpen = true">
      {{ preview }}
      <br />
      vom {{ incident.created_at | moment("L") }}
    </small>
  </td>
  <BModal v-model="modalOpen">
    <div
      v-if="modalOpen"
      v-html="$options.filters.tohtml(incident.text)"
    ></div>
    <MutingForm @submit="createMuting" v-if="muteModalOpen"></MutingForm>
    <template v-slot:footer>
      <div class="btn-group">
        <a
          v-if="canCloseWithoutVorgang"
          class="btn btn-sm btn-success"
          @click="closeWithoutIncident"
        >
          <i class="fa-check-square-o fa fa-fw"></i>Abhaken ohne Vorgang
        </a>
        <a
          v-if="canMute"
          class="btn btn-sm btn-default"
          @click="openMuteModal"
        >
          <i class="fa-microphone-slash fa fa-fw"></i>Muten
        </a>
        <a
          class="btn btn-sm btn-primary"
          :href="incidentCompletUrl"
          target="_blank"
        >
          <i class="fa-play fa fa-fw"></i>Vorgang anlegen und erledigen
        </a>
      </div>
    </template>
  </BModal>
</tr>
