
.list-group-item(:class="{ closed: closed }")
  div(style="flex-grow: 1")
    | {{ message.parsed_data.name }}
    |

    a.btn-xs.btn.btn-default(v-if="xingUrl", :href="xingUrl", target="_blank")
      svg(style="width:15px;height:15px", viewBox="0 0 24 24")
        path(
          fill="currentColor"
          d="M4.8,3C3.8,3 3,3.8 3,4.8V19.2C3,20.2 3.8,21 4.8,21H19.2C20.2,21 21,20.2 21,19.2V4.8C21,3.8 20.2,3 19.2,3M16.07,5H18.11C18.23,5 18.33,5.04 18.37,5.13C18.43,5.22 18.43,5.33 18.37,5.44L13.9,13.36L16.75,18.56C16.81,18.67 16.81,18.78 16.75,18.87C16.7,18.95 16.61,19 16.5,19H14.47C14.16,19 14,18.79 13.91,18.61L11.04,13.35C11.18,13.1 15.53,5.39 15.53,5.39C15.64,5.19 15.77,5 16.07,5M7.09,7.76H9.1C9.41,7.76 9.57,7.96 9.67,8.15L11.06,10.57C10.97,10.71 8.88,14.42 8.88,14.42C8.77,14.61 8.63,14.81 8.32,14.81H6.3C6.18,14.81 6.09,14.76 6.04,14.67C6,14.59 6,14.47 6.04,14.36L8.18,10.57L6.82,8.2C6.77,8.09 6.75,8 6.81,7.89C6.86,7.81 6.96,7.76 7.09,7.76Z"
        )

    |
    |
    small.text-muted
      | {{ message.parsed_data.company }}
      |
      a.btn.btn-xs.btn-default(v-if="message.person" :href="'/organisationen/' + message.person.Organisation + '#person_' + message.person.ID")
        | CRM

      a.btn.btn-xs.btn-default(v-if="message.weaver_organisation" :href="'/weaver/organisations/' + message.weaver_organisation.id")
        | Weaver
      |
      | {{ dateFormat(message.created_at) }}

    new-job(v-if="message.type == 'new_job'" :message="message")
    .d-flex.flex-wrap(v-else)
      img(:src='msBookingsIcon' v-if='message.type == "ms_booking"' style='width: 20px; height: 20px; margin-right: 5px;')
      svg(v-else style="width:24px;height:24px", viewBox="0 0 24 24")
        path(fill="currentColor", :d="message.icon")
      div
        span(v-html='message.summary')
        div(v-if='message.subject && message.type == "unknown_mail_copy"'): q
          | {{ message.subject }}
  div: .btn-group
    a.btn.btn-sm.btn-outline-primary.quick-action-btn(
      v-for="action in message.quick_actions"
      @click.prevent="quickAction(action)"
      :disabled="isLoading"
    )
      svg(style="width:16px;height:16px", viewBox="0 0 24 24")
        path(
          fill="currentColor"
          d="M11.5,20L16.36,10.27H13V4L8,13.73H11.5V20M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z"
        )
      | {{ humanize(action) }}

    a.btn-sm.btn.btn-outline-dark(
      @click.prevent="openModal = true"
      href="#"
      :disabled="isLoading"
    )
      span(v-if="message.person || message.new_organisation")
        | Firma ändern
      span(v-else)
        | Firma/Person anlegen

    a.btn-sm.btn.btn-outline-dark(
      v-if="message.person"
      :href="createVorgangUrl"
      :disabled="isLoading"
    )
      | + Vorgang
    a.btn-sm.btn.btn-outline-primary(
      v-if='!closed'
      @click.prevent="closeLead"
      href="#"
      :disabled="closed || isLoading"
    )
      | Abhaken
    a.btn-sm.btn.btn-outline-primary(
      v-if='closed'
      @click.prevent="reopenLead"
      href="#"
    )
      | Wieder öffnen
    button.btn.btn-outline-dark.manager-change-button(
      @click="managerModalOpen = true"
    )
      img.manager-image.img-circle(v-if="managerImage" :src="managerImage")
      span(v-else-if='message.assigned_to')
        | {{ message.assigned_to.ldap_login }}

  b-modal(
    v-model="openModal"
    title="Organisation / Person anlegen"
    size='lg'
    :hide-footer="true"
  )
    merge-person-modal(:message="message", @close="close", v-if="openModal")

  b-modal(v-model="managerModalOpen", title="Manager ändern", :hide-footer="true")
    change-community-manager-modal(
      :message="message"
      :communityManagers="communityManagers"
      @close="close"
      v-if="managerModalOpen"
    )
