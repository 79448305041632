
<table class="table table-striped table-bordered table-sm">
  <thead>
    <template v-for="headline in headlines">
      <SortHeadline
        v-if="headline.sortKey"
        :headline="headline"
        :sort-state="sortState"
        @update="toggleSort"
      >
        {{ headline.text }}
      </SortHeadline>
      <th v-else :key="headline.text" :title="headline.title">
        {{ headline.text }}
      </th>
    </template>
  </thead>
  <tbody>
    <slot :entries="sortedEntries"> </slot>
  </tbody>
</table>
