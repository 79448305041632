
div
  div(v-for="thema in themen", :key="thema.id")
    b-form-checkbox(v-model="selectedTopics", :value="thema.id")
      | {{ thema.name }}
      br
      small.text-muted
        | {{ thema.description }}
    transition(name="fade")
      div(v-if="selectedTopics.includes(thema.id)")
        textarea.form-control.mb-2(
          v-model="notes[thema.id]"
          placeholder="Notizen zu diesem Thema: Was wurde entschieden? Soll der Service eingeführt werden, falls nein, warum nicht, etc."
        )
        .d-flex.flex-wrap.mb-3(style='gap: 3px')
          button.btn.btn-sm.btn-outline-dark(v-for='suggestion in suggestions' @click.prevent='toggleSuggestion(suggestion, thema)')
            |{{ suggestion }}

  input(:name="formName", :value="hiddenValue" type="hidden")
