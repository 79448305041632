
div
  .d-flex.justify-content-between
    small Vorschau:

    button.btn.btn-sm(type="button", @click="fetchPreview" title='Andere Vorschau laden')
      i.fa.fa-refresh
  table.table-sm.table
    tr
      th Betreff:
      td
        | {{ previewSubject }}
    tr
      th An:
      td(v-if='previewTo')
        | {{ previewTo.join(', ')}}
  iframe(:srcdoc='previewBody' src='/404' v-if='previewBody')

