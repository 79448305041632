
.d-flex.align-items-center
  input.form-control(@click.prevent="openModal = true" placeholder="Suche")

  b-modal(v-model="openModal", :hide-footer="true" size="xl" table-class='table-sm table-striped table-bordered')
    small.text-muted Tip: Diese Suche kann mit Shift+S aufgerufen werden, nach Eingabe eines Schlüsselwortes kann mit den Pfeiltasten + Enter eine Firma ausgewählt werden.
    .d-flex.input-group
      b-form-select(v-model="form.phone" :options="phoneOptions" style='max-width: 200px')
      input.form-control.flex-grow-1(
        autofocus
        v-model="form.q"
        placeholder="Suche"
        ref="input"
        @keydown.down="selectNext"
        @keydown.up="selectPrev"
        @keydown.enter="changeToSelected"
        @focus="focusMode = true"
        @blur="focusMode = false"
      )
      b-form-select(v-model="form.membership_type" :options="membershipOptions" style='max-width: 200px')

    small.text-muted Suchergebnisse in der Datenbank
    b-table(:items="users", :fields="fields", :sort-by.sync="sortBy", :sort-desc.sync="sortDesc" ref="table" show-empty)
      template(v-slot:empty="scope")
        span(v-if='isLoading')
          |..Lade
        span(v-else)
          |Keine CRM Suchergebnisse

      template(v-slot:cell(Bezeichnung)='data')
        span(:class='{selected: selectIndex == data.index && focusMode}')
          a(:href="'/organisationen/' + data.item.ID")
            |{{ data.value }}

    small.text-muted Suchergebnisse im Crawler/Weaver
    b-table(:items="weaverOrgs", :fields="weaverFields", :sort-by.sync="sortBy", :sort-desc.sync="sortDesc" ref="weaverTable" show-empty)
      template(v-slot:empty="scope")
        span(v-if='isLoading')
          |..Lade
        span(v-else)
          |Keine WeaverSuchergebnisse

      template(v-slot:cell(name)='data')
        a(:href="'/weaver/organisations/' + data.item.id")
          |{{ data.value }}
