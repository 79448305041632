
div
  table.table.table-striped(v-if="handlungskompetenz")
    thead
      tr
        th Handlungskompetenz
        th Benchmark
        th Eigene
        th Relative
    tbody
      tr(v-for="a in attributes", :key="a.attribute")
        th
          | {{ a.label }}
        td
          | {{ a.format.format(averages[a.attribute]) }}
        td(
          :class="{ 'text-success': averages[a.attribute] <= handlungskompetenz[a.attribute] }"
        )
          | {{ a.format.format(handlungskompetenz[a.attribute]) }}
        td
          | {{ Math.round((handlungskompetenz[a.attribute] / averages[a.attribute]) * 100) / 100 }}
    tfoot
      tr
        th
          | Gesamt Handlungskompetenz
        th
          | {{ format(averages.total) }}
        th
        th
          | {{ format(handlungskompetenz.total) }}
