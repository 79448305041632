
div.form-group
  label.control-label(for='formName')
    |{{label}}
  input(type='hidden' :name="formName" :value='value' ref='hiddenInput')
  .input-group
    flat-pickr.form-control(v-model='value' :config='fromConfig' ref='picker')
    span.input-group-addon
      button(class="btn btn-outline-secondary" type="button" title="Toggle" data-toggle)
        i.fa.fa-fw.fa-calendar
          span(aria-hidden="true" class="sr-only") Toggle
