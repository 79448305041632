
<tr>
  <td>
    <a :href="'/organisationen/' + organisation.ID" target="_blank">
      {{ organisation.Bezeichnung }} <br />
      <small class="text-muted"> {{ organisation.Nutzername }} </small>
    </a>
  </td>
  <td>
    <span v-if="organisation.kundenbeziehung == 'ernst'">
      <i class="fa fa-fw fa-black-tie"></i> Ernst
    </span>
    <span v-if="organisation.kundenbeziehung == 'locker'">
      <i class="fa fa-fw fa-handshake-o"></i> Locker
    </span>
    <span v-if="organisation.kundenbeziehung == 'problemkunde'" title="Problemkunde">
      <i class="fa fa-fw fa-exclamation-circle text-danger"></i> Probl.
    </span>
  </td>
  <td style="text-center">
    {{ organisation.organisationstyp }}
  </td>
  <td style="text-align: right; padding-right: 10px;">
    {{ reviewInfo["Kundenaufwand"].value }}
  </td>
  <td><ValueWithFormatting :value="reviewInfo['Backlink']" /></td>
  <td><ValueWithFormatting :value="reviewInfo['Plakat']" /></td>
  <td>
    <div class="quick-actions">
      <a
        class="btn btn-sm btn-primary js-modal"
        title="Maßnahme/Incident anlegen"
        :href="'/incidents/new?organisation_id=' + organisation.ID"
      >
        <i class="fa fa-thumb-tack fa-fw"></i>
      </a>
      <a
        class="btn btn-sm btn-primary js-modal"
        title="Vorgang anlegen"
        :href="'/vorgaenge/new?back=%2F&Organisation=' + organisation.ID"
      >
        <i class="fa fa-plus fa-fw"></i>
      </a>
    </div>
  </td>
</tr>
