
div(ref='root')
  editor(
    v-model='body'
    :placeholders='placeholders'
    :templateUrl='templateUrl'
    :slim='slim'
  )
  input(type='hidden' :value='body' :name='formName')

  .card.mt-2(v-if='previewUrl'): .card-body
    .d-flex.justify-content-between
      strong Vorschau

      button.btn.btn-xs.btn-outline-dark(@click.prevent='refreshPreview')
        |refresh

    div.border
      div.d-flex.align-items-center.border.p-2(style='gap: 5px')
        small.text-muted Betreff:
        div
          | {{previewSubject }}
      iframe(:srcdoc='previewBody' src='/404' v-if='previewBody')

