
<div
  class="panel panel-primary"
  style="height: auto; transition: height 0.3s ease-in"
>
  <div class="panel-heading">
    <h3 class="panel-title">
      <a href="#customer-board" data-toggle="collapse" @click="loadData">
        Bestandskunden und Fördererübersicht
      </a>
    </h3>
  </div>
  <div id="customer-board" class="collapse show">
    <ul class="nav nav-tabs">
      <li class="nav-item align-self-center mx-2"><a class="label-tab">Bestandskunden</a></li>
      <li class="nav-item" :class="{ active: activeTab == 'partner' }">
        <a class="nav-link" @click="setTab('partner')">Partner</a>
      </li>
      <li class="nav-item" :class="{ active: activeTab == 'funnel' }">
        <a class="nav-link" @click="setTab('funnel')">
          Akquise-Funnel
          <span class='badge badge-pill badge-primary'>NEU</span>
        </a>
      </li>
      <li
        class="nav-item"
        :class="{ active: activeTab == 'foerderer_unternehmen' }"
      >
        <a class="nav-link" @click="setTab('foerderer_unternehmen')"
          >Förderer-Unternehmen</a
        >
      </li>
      <li class="nav-item" :class="{ active: activeTab == 'foerderer' }">
        <a class="nav-link" @click="setTab('foerderer')">Andere Förderer</a>
      </li>
      <li class="nav-item" :class="{ active: activeTab == 'interessenten' }">
        <a class="nav-link" @click="setTab('interessenten')">Interessenten</a>
      </li>
    </ul>
    <div style="min-height: 300px; transition: height 0.5s ease-in">
      <div v-if="isLoading" class="panel-body text-center">
        <PulseLoader :loading="isLoading" />
      </div>
      <PartnerTableV2
        v-if="activeTab == 'partner' && !isLoading"
        :services="services"
        :communityManagers="communityManagers"
      />
      <AkquiseFunnel
        v-if="activeTab == 'funnel'"
      />
      <FoerdererUnternehmenTable
        v-if="activeTab == 'foerderer_unternehmen' && !isLoading"
        :organisations="organisations"
        :services="services"
      />
      <FoerdererTable
        v-if="activeTab == 'foerderer' && !isLoading"
        :organisations="organisations"
      />
      <InteressentenTable
        v-if="activeTab == 'interessenten' && !isLoading"
        :organisations="organisations"
      />
    </div>
  </div>
</div>
