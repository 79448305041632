
<div>
  <div class="panel panel-primary">
    <div class="panel-heading d-flex">
      <h3 class="panel-title mr-2">
        <a href="#incident-board" data-toggle="collapse">Incidents</a>
      </h3>

      <ul class="nav nav-pills line-height-1">
        <li class="nav-item" :class="{ active: tab == 1 }">
          <a class="nav-link" @click.prevent="tab = 1" href="#">
            Eigene
            <span class="badge badge-dark">{{ myIncidents.length }}</span>
            <span
              v-if="myUrgentIncidents > 0"
              class="badge badge-danger"
              title="Dringende Incidents"
              >{{ myUrgentIncidents }}</span
            >
          </a>
        </li>
        <li class="nav-item" :class="{ active: tab == 2 }">
          <a class="nav-link" @click.prevent="tab = 2" href="#"
            >Unzugewiesene
            <span class="badge badge-dark">{{
              unassignedIncidents.length
            }}</span>
            <span
              v-if="unassignedUrgentIncidents > 0"
              title="Dringende Incidents"
              class="badge badge-danger"
              >{{ unassignedUrgentIncidents }}</span
            >
          </a>
        </li>
      </ul>
    </div>
    <div
      v-if="isLoading"
      class="panel-body"
      style="text-align: center; margin-top: 50px"
    >
      <PulseLoader :loading="isLoading" />
    </div>
    <div class="collapse show" v-if="!isLoading" id="incident-board">
      <IncidentTable
        :incidents="myIncidents"
        label="Eigene Incidents"
        v-if="tab == 1"
      />
      <IncidentTable
        :incidents="unassignedIncidents"
        label="Unzugewiesene Incidents"
        v-else
      />
    </div>
  </div>
</div>
