
b-table.table-sm(
  :fields="fields"
  striped
  hover,
  :items="value"
  responsive
  primary-key="id",
  :tbody-transition-props="{ name: 'flip-list' }"
  show-empty,
  :sortBy.sync="sortBy"
  tbody-tr-class="flip-list-item"
)
  template(v-slot:cell(organisation.Bezeichnung)=" { item }")
    a(:href="'/organisationen/' + item.organisation.ID")
      | {{ item.organisation.Bezeichnung }}

  template(v-slot:cell(Typ)="{ item }")
    | {{ item.typ.Bezeichnung }}
    .themen-wrapper(v-if="item.thema_notes.length > 0")
      small.label.label-default.mr-1(:key="thema.id" :title='thema.body || "Thema ohne Kommentar"' v-b-tooltip v-for="thema in item.thema_notes")
        i.fa.fa-comment.mr-1(v-if="thema.body")
        | {{ thema.thema_name }}

    div(v-if="item.Kommentar" style='line-height: 1.1;')
      small {{ item.Kommentar }}

    div(v-if="item.termin")
      span.badge.badge-dark(:title="item.termin.Ort")
        i.fa-clock-o.fa.fa-fw
        | {{ item.termin.Beginn | moment('L LT') }}

    div(v-if="item.akquisegefuehl")
      small Akquisechance
      span.label.label-default {{ item.akquisegefuehl }}

    div(v-if="item.customer_first_contacted")
      span.label.label-success
        | Erstkontakt durch Kunden
    div(v-if='item.translated_new_akquisestatus && item.translated_old_akquisestatus')
      span.label.label-default(title='Akquisestatus geändert')
        |{{ item.translated_old_akquisestatus }} → {{ item.translated_new_akquisestatus }}

  template(v-slot:cell(CommunityManager)="{ item }")
    community-manager-chip(v-if="item.community_manager", :community-manager="item.community_manager")
    span(v-else)
      | {{ item.cm_name }}
    community-manager-chip(v-for="cm in item.participating_community_managers", :community-manager="cm", :key="cm.id")

    div(v-if="item.GebuchteZeit && item.GebuchteZeit > 0")
      small
        i.fa.fa-fw.fa-clock-o
        | {{ item.GebuchteZeit }}m
    div(v-if="item.dienstreise_hin")
      small(title="Anreise")
        i.fa.fa-car
        i.fa.fa-fw.fa-step-forward
        | {{ item.dienstreise_hin.Reisezeit }}m
    div(v-if="item.dienstreise_zurueck")
      small(title="Abreise")
        i.fa.fa-car
        i.fa.fa-fw.fa-step-backward
        | {{ item.dienstreise_zurueck.Reisezeit }}m

  template(v-slot:cell(Beginn)="{ item }")
    | {{ item.Beginn | moment('L') }}

  template(v-slot:cell(Erinnerung)="{ item }")
    span(v-if="item.Erinnerung" title="Erinnerung")
      i.fa-clock.fa-fw.fa
      | {{ item.Erinnerung | moment('L') }}

    .label.label-default(v-for="mail in item.queued_mails", :key="mail.id" style="display: block")
      a.js-modal(:href="'/queued_mails/' + mail.id")
        | Mail vom {{ mail.send_on | moment('L') }}
        br
        span.text-truncate(:title="mail.subject")
          | {{ mail.subject | truncate(20) }}

  template(v-slot:cell(Person)="{ item }")
    person-chip(v-for="person in item.personen", :person="person", :key="person.id")

  template(v-slot:cell(actions)="{ item }")
    .btn-group.d-flex
      a.btn.btn-sm.btn-default.js-modal(v-if="item.can_edit", :href="'/vorgaenge/' + item.ID + '/edit'" title="Bearbeiten")
        i.fa.fa-pencil-square-o.fa-fw
      a.btn.btn-sm.btn-warning.js-modal(
        v-if="item.can_destroy",
        :href="'/vorgaenge/' + item.ID"
        title="Löschen"
        data-method="delete"
        data-confirm="Löschen?"
      )
        i.fa.fa-trash-o.fa-fw
      a.btn.btn-sm.btn-default(v-if="item.Mahnschreiben", :href="'/vorgaenge/' + item.ID + '.pdf'" title="Mahnschreiben PDF")
        i.fa.fa-paperclip.fa-fw

      template(v-if="item.Erinnerung && item.CommunityManager == currentCm.id")
        a.js-modal.btn.btn-sm.btn-primary(
          :href="'/vorgaenge/new?Organisation=' + item.Organisation + '&vorgaenger=' + item.ID"
          title="Nachfolgevorgang anlegen und Erinnerung löschen"
        )
          i.fa.fa-plus.fa-fw
        a.btn.btn-sm.btn-default(@click="deleteReminder(item)" title='Termin Erinnerung löschen')
          i.fa.fa-calendar-times-o.fa-fw
