
.panel.panel-primary
  .panel-heading
    h3.panel-title Review-Termine

    ul.nav.nav-pills.line-height-1.flex-grow-1(v-if='false')
      li.nav-item(:class="{active: tab === 1}")
        a.nav-link(href='#' @click='tab = 1') Eigene Kunden
      li.nav-item(:class="{active: tab === 2}")
        a.nav-link(href='#' @click='tab = 2') Alle CMs
  highcharts(v-if='chartData' :options="chart")
