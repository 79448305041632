
.panel.panel-primary
  .panel-heading
    .d-flex
      h3.panel-title.mr-4: a(@click.prevent='show = !show' href='#')
        |Verlängerbare Mitglieder

      ul.nav.nav-pills.line-height-1.flex-grow-1
        li.nav-item(:class='{active: tab === 1}')
          a.nav-link(@click='tab = 1')
            |Verlängerbar (1-Monat)
            |
            span.badge.badge-dark
              | {{ nowRenewable.length }}
        li.nav-item(:class='{active: tab === 2}')
          a.nav-link(@click='tab = 2')
            |Alle
            |

            span.badge.badge-dark
              | {{ renewable.length }}

      div
        a.btn.btn-outline-dark.btn-sm(@click='showAll = true' v-if='isAdmin && !showAll')
          |Alle CMs
  div(v-if='isLoading' style='height: 400px')
    .text-center: i.fa.fa-spinner.fa-spin.fa-3x
  b-table(:items="showItems" :fields="fields" v-if='showItems.length > 0 && show' hover striped small table-class='mb-0')
    template(v-slot:cell(organisation.name)="{ item }")
      a(:href="'/organisationen/' + item.organisation.id")
        |{{ item.organisation.name }}

    template(v-slot:cell(price.netto)="{ item }")
      |{{ item.price.nettoFormatted }}
      div: small
        | {{ item.employeeLevel ? item.employeeLevel.level : '' }}

    template(v-slot:cell(actions)="{ item }")
      .btn-group.text-right
        a.btn.btn-sm.btn-outline-dark.js-modal(:href='"/angebote/" + item.id + "/edit"' v-if='item.canEdit')
          i.fa.fa-pencil-square-o.fa-fw
        a.btn.btn-sm.btn-outline-dark.js-modal(:href='"/angebote/" + item.id + "/cancellation/new"' v-if='item.canCancel')
          i.fa.fa-ban.fa-fw
        a.btn.btn-sm.btn-outline-primary.js-modal(:href='"/angebote/" + item.id + "/renewal/new"' v-if='item.canRenew')
          i.fa.fa-refresh.fa-fw
