
div.text-center
  div(v-if='reviewNotDesired')
    span.badge.badge-danger Kein Review erwünscht
  div(v-else)
    value-with-formatting(:value='reviewgespraech' v-if='reviewgespraech' style='justify-content: flex-start')

  div.text-left(v-if='appointments.length > 0')
    .card.p-1(v-for='appointment in appointments' style='width: fit-content')
      div
        span.badge.badge-dark Termin
        |
        |{{ appointment.termin.from | moment("LL") }}
      br
      |
      small(style='max-width: 150px')
        |{{ appointment.termin.topic }}

