
div
  activestorage-upload(@error='onUploadError' @uploaded='addUpload')

  .list-group-item(v-for='upload in uploads')
    .d-flex.justify-content-between.align-items-center
      span(v-if='upload.url')
        a(:href='upload.url' target="_blank")
          span
            | {{ upload.filename }}
      span(v-else)
        | {{ upload.filename }}
      small.text-muted
        | {{ upload.byte_size | sizeToMb }}
      button.btn.btn-xs.btn-outline-danger(type='button' @click='removeUpload(upload)' title='Löschen')
        i.fa.fa-trash

