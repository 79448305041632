
a.btn.btn-default.btn-sm(:href='url' @click.prevent='showPdf = true' v-bind="$attrs" :class='btnClass' :title='title || text')
  i.fa.fa-paperclip.fa-fw
  |
  template(v-if='text')
    | {{ text }}
  b-modal.pdf-modal(v-model='showPdf', size='xl' v-if='showPdf' modal-class='pdf-modal')
    pdf-viewer(:url='url')
    template(v-slot:modal-footer='{ cancel }')
      a.btn.btn-primary.btn-sm(:href='url')
        | Download
      button.btn.btn-secondary.btn-sm(type='button' @click='showPdf = false')
        | Schließen

